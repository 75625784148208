import { object, string, number } from 'yup';

const pslfEstimatorSchema = object({
  federalLoanTime: string()
    .oneOf(['before-october-2007', 'after-october-2007'])
    .required('Required'),
  householdIncome: number().positive('Income must be greater than $0').required('Required'),
  householdSize: number().moreThan(0, 'Family size must be 1 or greater').required('Required'),
  madePayments: number().required('Required'),
  academicDegree: string()
    .oneOf(['undergradOnly', 'GradOnly', 'undergradGrad'])
    .required('Required'),
});

const idrEstimatorSchema = object({
  federalLoanTime: string()
    .oneOf(['before-october-2007', 'after-october-2007'])
    .required('Required'),
  householdIncome: number().positive('Income must be greater than $0').required('Required'),
  householdSize: number().moreThan(0, 'Family size must be 1 or greater').required('Required'),
  madePayments: number().required('Required'),
  affordPayments: string().oneOf(['yes', 'no']).required('Required'),
});

const strategyFinderFormSchema = object({
  payoffGoal: string().oneOf(['quick', 'slow'], 'Please select a response').required('Required'),
  affordPayments: string().oneOf(['yes', 'no'], 'Please select a response').required('Required'),
  federalLoanTime: string()
    .oneOf(['before-october-2007', 'after-october-2007'], 'Please select a response')
    .required('Required'),
  currentlyWorkingToward: string()
    .oneOf(['yes', 'no-and-not-interested', 'no-but-want-learn'], 'Please select a response')
    .required('Required'),
  currentForgivenessProgram: string().when('currentlyWorkingToward', {
    is: 'yes',
    then: () =>
      string()
        .oneOf(
          ['PublicServiceLoanForgiveness', 'TeacherLoanForgiveness', 'IncomeDrivenLoanForgiveness'],
          'Please select a program'
        )
        .required('Required'),
  }),
  madePayments: number().when('currentlyWorkingToward', {
    is: 'yes',
    then: () => number().required('Required'),
  }),
  employmentType: string()
    .oneOf(
      [
        'government-tribal-nonprofit',
        'teacher',
        'other-private-sector',
        'teacher&government-tribal-nonprofit',
      ],
      'Please select any that apply'
    )
    .required('Required'),
  madeLimitedWaiverPayments: number().required('Required'),
  planInGov: string().when('employmentType', {
    is: 'government-tribal-nonprofit' || 'teacher&government-tribal-nonprofit',
    then: () =>
      string()
        .oneOf(['for-10-years-more', 'not-for-10-years'], 'Please select a response')
        .required('Required'),
  }),
  planAsTeacher: string().when('employmentType', {
    is: 'teacher' || 'teacher&government-tribal-nonprofit',
    then: () =>
      string()
        .oneOf(['for-5-years-and-more', 'less-than-5-years'], 'Please select a response')
        .required('Required'),
  }),
  householdIncome: number().positive('Income must be greater than $0').required('Required'),
  householdSize: number().moreThan(0, 'Family size must be 1 or greater').required('Required'),
  residenceState: string().required('Required'),
  academicDegree: string()
    .oneOf(['undergradOnly', 'GradOnly', 'undergradGrad'], 'Please select a response')
    .required('Required'),
});

export { pslfEstimatorSchema, idrEstimatorSchema, strategyFinderFormSchema };

export default null; // silence a false warning
