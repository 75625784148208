import Model, { attr, belongsTo } from '@ember-data/model';
import type TASApplicationModel from './tas-application.ts';

export default class TasAssistanceModel extends Model {
  @attr declare state: string;
  @attr declare amount: number;
  @attr declare fields: Record<string, unknown>;
  @attr declare transitionLog: Record<string, unknown>[];

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('tas-application', { async: false, inverse: 'tasApplication' })
  declare tasApplication: TASApplicationModel;

  get isSettled() {
    return this.state === 'TAS.AssistanceState.SETTLED';
  }

  get settledDate(): string | null {
    const settledTransition = this.transitionLog
      .slice()
      .reverse()
      .find((log) => log.to === 'TAS.AssistanceState.SETTLED');

    return settledTransition ? (settledTransition.timestamp as string) : null;
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'tas-assistance': TasAssistanceModel;
  }
}
