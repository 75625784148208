import Model, { attr, belongsTo } from '@ember-data/model';
import type SearchableModel from './searchable.ts';

export default class SearchModel extends Model {
  @attr declare searchableId: string;
  @attr declare searchableType: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('searchable', {
    async: false,
    polymorphic: true,
    inverse: 'search',
  })
  declare searchable: SearchableModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    search: SearchModel;
  }
}
