import Model, { belongsTo } from '@ember-data/model';
import type SearchModel from './search.ts';

export default class SearchableModel extends Model {
  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('search', { async: false, inverse: 'searchable', as: 'searchable' })
  declare search: SearchModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    searchable: SearchableModel;
  }
}
