import Service, { service } from '@ember/service';
import { action } from '@ember/object';
import { getSingleValueForTasField } from '../utils/tuition-assistance/fields.ts';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import type EmployeeModel from '../models/employee.ts';
import type FeaturesService from './features.ts';
import type LimitModel from '../models/limit.ts';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type Store from '@ember-data/store';
// @ts-expect-error: this doesn't exist yet
import type SessionContextService from './session-context.ts';
import type TASApplicationModel from '../models/tas-application.ts';
import type TasAssistanceModel from '../models/tas-assistance.ts';
import type TasEligibilityModel from '../models/tas-eligibility.ts';
import type TasParticipantModel from '../models/tas-participant.ts';
import type TASProgramTemplateModel from '../models/tas-program-template.ts';
import type TuitionAssistanceLimitsSummaryComponent from '../components/tuition-assistance/limits-summary.gts';

type LimitsRequestParamsSignature = {
  employee_ids: string[];
  excluded_item_ids: string[];
  company_id: string;
  product: string;
  scheme?: string;
  batch?: string;
  group?: string | null;
  date?: string;
  date_based_on?: string;
  course_start?: string;
  course_end?: string;
  end_of_year_cutoff?: number | null;
  requester: string | null;
  approval?: string | null;
  payment_date?: string;
  dependent_id?: string;
};

type LimitsStatusRequestResponseSignature = {
  data: LimitsStatusDataSignature[];
};

type LimitName =
  | 'company_annual'
  | 'company'
  | 'product_annual'
  | 'product'
  | 'batch_annual'
  | 'batch'
  | 'scheme_annual'
  | 'scheme'
  | 'none';

export type LimitsStatusDataSignature = {
  annual_end_date: string;
  annual_start_date: string;
  available: number | null;
  available_with_override: number | null;
  details: {
    company: LimitsDetailSignature;
    product: LimitsDetailSignature;
    batch: LimitsDetailSignature;
    scheme: LimitsDetailSignature;
  };
  employee_id: string;
  hard_limit: LimitName;
  product: string | null;
  scheme: string | null;
  soft_limit: LimitName;
};

export type LimitsDetailSignature = {
  annual_limit: number | null;
  annual_usage: number;
  available: number | null;
  hard: boolean | null;
  limit: number | null;
  one_month_limit: number | null;
  one_month_usage: number;
  six_month_limit: number | null;
  six_month_usage: number;
  taxable_limit: number | null;
  three_month_limit: number | null;
  three_month_usage: number;
  usage: number;
};

type ApplicationValidationResponseSignature = {
  can_submit: boolean;
  reduction_needed: number;
};

export default class TuitionAssistanceService extends Service {
  @service declare store: typeof Store;
  @service declare sessionContext: SessionContextService;
  @service declare features: FeaturesService;

  activeLimitsSummaryComponents: Set<TuitionAssistanceLimitsSummaryComponent> = new Set();

  @tracked activeEligibilities: TasEligibilityModel[] = [];

  get host() {
    return this.store.adapterFor('application').host;
  }

  get headers() {
    return this.store.adapterFor('application').headers;
  }

  @action
  async getLimitForTasApplication(
    tasApplication: TASApplicationModel
  ): Promise<LimitModel | undefined> {
    const url = `${this.host}/tas-applications/${tasApplication.id}/limit`;

    try {
      const response = await fetch(url, {
        headers: this.headers,
        method: 'GET',
      });

      const parsed = await response.json();

      this.store.pushPayload(parsed);

      const limitModel = this.store.peekRecord('limit', parsed.data.id);

      return limitModel;
    } catch (e) {
      console.error(`Error fetching limit for TasApplication ${tasApplication.id}`, {
        tasApplicationId: tasApplication.id,
        url,
        error: e,
      });
    }
  }

  @action
  async getApplicationLimitsDataForEmployee(
    employee: EmployeeModel,
    applicationLimit: LimitModel | undefined,
    application: TASApplicationModel
  ): Promise<LimitsStatusDataSignature | undefined> {
    const adapter = this.store.adapterFor('tas-application');

    const scheme = applicationLimit?.scheme;
    const batchGroup = applicationLimit?.group;

    const url = `${this.host}/limits/status`;

    const company = employee.company;

    if (!company) {
      console.error(
        'Error in `getApplicationLimitsDataForEmployee`: Company is not defined on employee.'
      );
    }

    const params: LimitsRequestParamsSignature = {
      employee_ids: [employee.id],
      excluded_item_ids: [],
      company_id: company?.id,
      product: 'TA',
      scheme: scheme,
      requester: 'employee',
      batch: batchGroup,
    };

    const programTemplate = application?.tasProgramInstance?.tasProgramTemplate;

    if (application && !programTemplate) {
      console.error(
        'Error in `getApplicationLimitsDataForEmployee`: An application was passed with no related program template loaded.'
      );
    }

    if (application && programTemplate) {
      params.group = programTemplate.id;
      params.course_start = getSingleValueForTasField('COURSES_BEGIN_DATE', application.fields) as
        | string
        | undefined;
      params.course_end = getSingleValueForTasField('COURSES_END_DATE', application.fields) as
        | string
        | undefined;
      params.date_based_on = getSingleValueForTasField(
        'CALCULATE_TOWARDS_TOTAL_BASED_ON',
        programTemplate.fields
      ) as string | undefined;
      params.end_of_year_cutoff = getSingleValueForTasField(
        'PAYMENT_SUBMISSION_MIN_DAYS_BEFORE_YEAR_END',
        programTemplate.fields
      ) as number | null | undefined;

      if (this.features.isAdminApp) {
        params.requester = this.sessionContext.user.isTasApprover ? 'approver' : 'admin';
      } else if (
        `${this.sessionContext.currentRole?.relationshipType}`.startsWith('TAS.Approver')
      ) {
        params.requester = 'approver';
      }

      switch (application.state) {
        case 'TAS.ApplicationState.PENDING_EVIDENCE_APPROVAL':
          params.approval = 'evidence';
          break;
        case 'TAS.ApplicationState.PENDING_COURSES_APPROVAL':
          params.approval = 'course';
          break;
        case 'TAS.ApplicationState.FULFILLED':
          params.payment_date = application.latestPaymentDate;
          break;
        default:
          params.approval = null;
      }

      if (application.tasProgramInstance.isDependentProgram) {
        params.dependent_id = application.tasProgramInstance.dependent?.id;
        params.product = 'DEPENDENT_TA';
      }

      if (!application.isDormantState) {
        try {
          const applicationWithAssistances = await this.store.findRecord(
            'tas-application',
            application.id,
            {
              include: 'tas-assistances',
              reload: true,
            }
          );
          const assistanceIds = applicationWithAssistances.tasAssistances.map(
            (assistance: TasAssistanceModel) => assistance.id
          );
          params.excluded_item_ids = assistanceIds;
        } catch (e) {
          console.error(
            'Error in `getApplicationLimitsDataForEmployee`: Unable to fetch tas assistances to exclude',
            {
              params,
              error: e,
            }
          );
        }
      }
    }

    try {
      const limitsData: LimitsStatusRequestResponseSignature | undefined = await adapter.ajax(
        url,
        'GET',
        {
          data: params,
        }
      );

      return limitsData?.data?.[0];
    } catch (e) {
      console.error(`Unable to fetch limits status`, {
        params,
        error: e,
      });
    }
  }

  @action
  async getApplicationLimitStatusByEmployeeId(
    employee: EmployeeModel
  ): Promise<LimitsStatusDataSignature | undefined> {
    const adapter = this.store.adapterFor('tas-application');

    const url = `${this.host}/limits/status`;

    const company = employee.company;

    if (!company) {
      console.error(
        'Error in `getApplicationLimitStatusByEmployee`: Company is not defined on employee.'
      );
    }

    const params: LimitsRequestParamsSignature = {
      employee_ids: [employee.id],
      excluded_item_ids: [],
      company_id: company?.id,
      requester: 'employee',
      product: 'TA',
    };

    try {
      const limitsStatus = await adapter.ajax(url, 'GET', {
        data: params,
      });
      const limitsData: LimitsStatusDataSignature = limitsStatus?.data?.[0];
      return limitsData;
    } catch (error) {
      console.error('Error fetching limits status:', error);
      throw error;
    }
  }

  @action
  async validateAmountRequestedAgainstCurrentLimitsStatus(
    application: TASApplicationModel,
    employee: EmployeeModel
  ): Promise<ApplicationValidationResponseSignature> {
    const allowSubmissionBeyondLimits =
      getSingleValueForTasField(
        'ALLOW_PRE_APPROVAL_SUBMISSION_BEYOND_LIMIT',
        application.tasProgramInstance.tasProgramTemplate.fields
      ) || false;
    if (allowSubmissionBeyondLimits) {
      return {
        can_submit: true,
        reduction_needed: 0,
      };
    }

    const applicationLimit = await this.getLimitForTasApplication(application);
    const limitStatus = await this.getApplicationLimitsDataForEmployee(
      employee,
      applicationLimit,
      application
    );

    const remainingBenefit = limitStatus?.available ?? null;
    if (remainingBenefit === null) {
      return {
        can_submit: true,
        reduction_needed: 0,
      };
    }
    const amountRequested = application.requestedTotal || 0;
    const remainder = remainingBenefit - amountRequested;
    const isValid = remainder >= 0;

    return {
      can_submit: isValid,
      reduction_needed: isValid ? 0 : Math.abs(remainder),
    };
  }

  @action
  triggerAutoEvidenceApprovalForApplication(
    application: TASApplicationModel,
    comment: string = ''
  ) {
    if (!getSingleValueForTasField('WAIVE_EVIDENCE_APPROVAL_REQUIREMENT', application.fields)) {
      console.error(
        `Application ${application.id} cannot be submitted for auto approval without evidence approval waiver.`
      );
      return;
    }

    this.autoApproveEvidenceTask.perform(application, comment);
  }

  /* At this point, the application should already have evidence approval waived by courses approval.
   * That means that as soon as we auto-request evidence approval, it should move to fulfilled as we expect.
   * This action lives on a service because of the distributed nature of the state machine. We need to ensure
   * this task is not cancelled because of a parent component being destroyed.
   * However, we do draw the line at 15 seconds of polling for the application
   * to move into valid state for requesting evidence approval.
   */
  autoApproveEvidenceTask = task(
    { maxConcurrency: 10, enqueue: true },
    async (application: TASApplicationModel, comment: string = '') => {
      let attemptCount = 0;

      while (application.state !== 'TAS.ApplicationState.ATTEND' && attemptCount < 30) {
        attemptCount++;
        await timeout(500);
        await application.reload();
      }

      try {
        await this.store.adapterFor('tas-application').requestCourseEvidence(application, comment);
      } catch (e) {
        console.error('autoApproveEvidenceTask encountered an error', {
          applicationId: application.id,
          error: e,
        });
      }
    }
  );

  @action
  registerLimitsSummaryComponent(component: TuitionAssistanceLimitsSummaryComponent) {
    this.activeLimitsSummaryComponents.add(component);
  }

  @action
  unregisterLimitsSummaryComponent(component: TuitionAssistanceLimitsSummaryComponent) {
    this.activeLimitsSummaryComponents.delete(component);
  }

  @action
  refreshActiveLimitsSummaryComponents() {
    try {
      this.activeLimitsSummaryComponents.forEach((component) => component.refreshData());
    } catch (e) {
      console.error('Error refreshing active limits summary components', {
        error: e,
      });
    }
  }

  @action
  async loadEligibilitiesForCurrentEmployee() {
    const participants = await this.store.query('tas-participant', {
      filter: {
        employee: this.sessionContext.currentEmployee.id,
      },
      include: 'tas-eligibilities',
    });

    this.activeEligibilities = (participants[0].tasEligibilities || []).filter(
      (model: TasEligibilityModel) => model.isActive
    );
  }

  @action
  hasActiveEligibilityForProgramTemplate(programTemplate: TASProgramTemplateModel) {
    return this.activeEligibilities.some(
      (eligibility: TasEligibilityModel) => eligibility.code === programTemplate.code
    );
  }

  @action
  waitingPeriodEndDate(waitingPeriod: number, tasParticipant: TasParticipantModel) {
    const hireDate = new Date(tasParticipant.employmentStartDate);
    const waitingPeriodToNumber = Number(waitingPeriod);
    if (!hireDate || waitingPeriodToNumber === 0) {
      return ''; // Return '' to indicate that the waiting period end date can't be calculated or is 0
    }

    const endDate = new Date(hireDate);
    endDate.setDate(hireDate.getDate() + waitingPeriodToNumber);
    return endDate;
  }

  @action
  ineligibleBasedOnWaitingPeriod(waitingPeriod: number, tasParticipant: TasParticipantModel) {
    const endDate = this.waitingPeriodEndDate(waitingPeriod, tasParticipant);
    if (!endDate) {
      return false; // If no end date, participant is not ineligible based on waiting period
    }

    const currentDate = new Date();

    return currentDate < endDate;
  }
}
