import { attr, belongsTo, hasMany } from '@ember-data/model';
import { getInitials } from '../utils/data/get-initials.ts';
import SearchableModel from './searchable.ts';
import type UserModel from './user.ts';
import type AccountModel from './account.ts';
import type EmailAddressModel from './email-address.ts';
import type EmployeeModel from './employee.ts';
import type PhoneNumberModel from './phone-number.ts';
import type PostalAddressModel from './postal-address.ts';
import type StrategyFinderResultModel from './strategy-finder-result.ts';
import type MessageModel from './message.ts';
import type AccountTransactionModel from './account-transaction.ts';

export default class PersonModel extends SearchableModel {
  @attr declare firstName: string;
  @attr declare lastName: string;
  @attr declare preferences: Record<string, unknown>[];

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('user', { async: false, inverse: 'person' })
  declare user: UserModel;
  @hasMany('account', { async: false, inverse: null })
  declare accounts: AccountModel[];
  @hasMany('email-address', { async: false, inverse: null })
  declare emailAddresses: EmailAddressModel[];
  @hasMany('employee', { async: false, inverse: 'person' })
  declare employees: EmployeeModel[];
  @hasMany('phone-number', { async: false, inverse: null })
  declare phoneNumbers: PhoneNumberModel[];
  @hasMany('postal-address', { async: false, inverse: null })
  declare postalAddresses: PostalAddressModel[];
  @hasMany('strategy-finder-result', { async: false, inverse: null })
  declare strategyFinderResults: StrategyFinderResultModel[];
  @hasMany('message', { async: false, inverse: 'person' })
  declare messages: MessageModel[];
  @hasMany('account-transaction', { async: false, inverse: 'person' })
  declare accountTransactions: AccountTransactionModel[];

  /**************************
   **  Computed Properties **
   **************************/

  get fullName() {
    return [this.firstName, this.lastName].join(' ');
  }

  get initials() {
    return getInitials(this);
  }

  get primaryEmail() {
    return this.emailAddresses.find((e) => e.contactType === 'PRIMARY');
  }

  get primaryPhoneNumber() {
    return this.phoneNumbers.find((e) => e.phoneNumberType === 'MOBILE');
  }

  get accountStats() {
    const totalDebt = this.totalLoanBalance;
    let totalLoans = 0;

    // TODO: Somehow just do all the loops once.
    this.activeAccounts.forEach((account) => {
      totalLoans += account.activeLoans.length;
    });

    return {
      totalDebt,
      totalLoans,
    };
  }

  get activeAccounts() {
    return this.accounts?.filter((e) => !e.deactivatedOn) || [];
  }

  get inactiveAccounts() {
    return this.accounts?.filter((e) => !!e.deactivatedOn) || [];
  }

  get totalLoanBalance() {
    let balance = 0;

    this.activeAccounts.forEach(
      (account) => (balance += account.deactivatedOn ? 0 : account.loanTotal)
    );
    return balance;
  }

  get weightedInterestRate() {
    return (
      this.activeAccounts.reduce(
        // @ts-expect-error: no type for this
        (a, { loanInterestAverage: b }) => a + parseFloat(b || 0.0),
        0.0
      ) / this.activeAccounts.length
    ).toFixed(2);
  }

  get allActiveAccountLoans() {
    return this.activeAccounts
      .reduce((allLoans, account) => {
        const loans = account.activeLoans.slice();
        // @ts-expect-error: all loans has a mismatch type
        return allLoans.concat(loans);
      }, [])
      .flat();
  }

  get needsToLinkLoans() {
    return this.activeAccounts.length === 0;
  }

  get reasonForNotLinkingLoans() {
    // @ts-expect-error: no type for preferences
    return this.preferences['reason-for-not-linking-loans'] as string;
  }

  get needsToAnswerWhyNoLinkedLoans() {
    return !!(this.needsToLinkLoans && !this.reasonForNotLinkingLoans);
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    person: PersonModel;
  }
}
