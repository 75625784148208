import Model, { attr, belongsTo } from '@ember-data/model';
import type UserModel from './user.ts';

export default class LoginModel extends Model {
  @attr declare userName: string;
  @attr declare provider: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('user', { async: false, inverse: null })
  declare user: UserModel;

  /*************************
   * Computed Properties  *
   *************************/

  get isEditable() {
    return this.provider === 'EMAIL';
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    login: LoginModel;
  }
}
