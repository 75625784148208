import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { service } from '@ember/service';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type Store from '@ember-data/store';
import type AccountingPaymentModel from './accounting-payment.ts';
import type AccountingLineItemModel from './accounting-line-item.ts';
import type AccountingTransactionModel from './accounting-transaction.ts';
import type CompanyModel from './company.ts';

type Stats = {
  // TODO: There seems to be a lot of mismatches between invoices and accounting invoices.
  subtotal: number;
  credits: number;
  // TODO: is total actually returned?
  total: number;
  count: {
    eligible: number;
    ineligible: number;
    maybe: number;
    pending_credit: number;
    locked: number;
    all: number;
    canceled: number;
    credit: number;
    disbursed: number;
  };
};

export default class AccountingInvoiceModel extends Model {
  @service declare store: typeof Store;

  @attr declare description: string;
  @attr declare invoiceDate: string;
  @attr declare invoicePath: string;
  @attr declare invoiceType: string;
  @attr declare period: string;
  @attr declare state: string;
  @attr declare tag: string;
  @attr declare stats: Stats;
  @attr declare createdAt: string;
  @attr declare updatedAt: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('accounting-payment', { async: false, inverse: null })
  declare accountingPayment: AccountingPaymentModel;

  @belongsTo('company', { async: false, inverse: null })
  declare company: CompanyModel;

  @hasMany('accounting-line-item', {
    async: false,
    inverse: 'accountingInvoice',
  })
  declare accountingLineItems: AccountingLineItemModel[];

  @hasMany('accounting-transaction', {
    async: false,
    inverse: 'accountingInvoice',
  })
  declare accountingTransactions: AccountingTransactionModel[];

  /**************************
   **  Computed Properties **
   **************************/

  get isCreated() {
    return this.state === 'CREATED';
  }

  get isApproved() {
    return this.state === 'APPROVED';
  }

  get isFunded() {
    return this.state === 'FUNDED';
  }

  get isDisbursed() {
    return this.state === 'DISBURSED';
  }

  get isDisbursing() {
    return this.state === 'DISBURSING';
  }

  get isAutomated() {
    return this.invoiceType === 'AUTOMATED';
  }

  get canUpdateLineItems() {
    // TODO: this should be false if theres also an unlocked adhoc invoice for the same period
    // protecting against that case in the backend for now
    return this.isCreated && this.isAutomated;
  }

  get canApprove() {
    return this.isCreated;
  }

  get canUnlock() {
    return this.isApproved;
  }

  get canVoid() {
    return this.isCreated || this.isApproved;
  }

  get canFund() {
    return this.isApproved;
  }

  get canEditLineItems() {
    return this.isCreated;
  }

  get canAddLineItems() {
    return this.isCreated;
  }

  /**************************
   **  Methods **
   **************************/

  async approve() {
    return this.store.adapterFor('accounting-invoice').approve(this);
  }

  async unlock() {
    return this.store.adapterFor('accounting-invoice').unlock(this);
  }

  async fund() {
    return this.store.adapterFor('accounting-invoice').fund(this);
  }

  async void() {
    return this.store.adapterFor('accounting-invoice').void(this);
  }

  async updateLineItems() {
    return this.store.adapterFor('accounting-invoice').updateLineItems(this);
  }

  // async downloadInvoices(invoiceIds: string[]) {
  //   // @ts-expect-error return when we have types
  //   return this.store
  //     .adapterFor('accounting-invoice')
  //     .downloadInvoices(invoiceIds);
  // }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'accounting-invoice': AccountingInvoiceModel;
  }
}
