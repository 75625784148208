import Model, { attr, belongsTo } from '@ember-data/model';
import type CompanyModel from './company.ts';
import type EmployeeModel from './employee.ts';
import type TasParticipantModel from './tas-participant.ts';

export default class TasEligibilityModel extends Model {
  @attr declare code: string;
  @attr declare state: string;

  get isActive() {
    return this.state === 'TAS.EligibilityState.ACTIVE';
  }

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('company', { async: false, inverse: null })
  declare company: CompanyModel;
  @belongsTo('employee', { async: false, inverse: null })
  declare employee: EmployeeModel;
  @belongsTo('tas-participant', { async: false, inverse: 'tasEligibilities' })
  declare tasParticipant: TasParticipantModel;
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'tas-eligibility': TasEligibilityModel;
  }
}
