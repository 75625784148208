import Model, { attr } from '@ember-data/model';

type Employee = {
  companyId: string;
  companyName: string;
  firstName: string;
  lastName: string;
  payrollId: string;
  email: string;
  tasParticipantState: string;
};

type Items = {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;

  employees: Employee[];
  isActive: boolean;
  userNames: string[];

  relationshipTypes: string[];
};
type People = {
  items: Items[];
  total: number;
  took: number;
};

export default class SearchPersonModel extends Model {
  @attr declare people: People;

  /**************************
   **  Computed Properties **
   ***************************/

  // get fullName() {
  //   return `${this.firstName} ${this.lastName}`;
  // }
}
