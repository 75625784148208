import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

type Positional = [string | undefined];

type Named = {
  month?: 'numeric' | '2-digit' | 'short' | 'long' | undefined;
  day?: 'numeric' | '2-digit' | undefined;
  year?: 'numeric' | '2-digit' | undefined;
  hour?: 'numeric' | '2-digit' | undefined;
  minute?: 'numeric' | '2-digit' | undefined;
  timeZone?: string | undefined;
  timeZoneName?: 'short' | 'long' | undefined;
  weekday?: 'narrow' | 'short' | 'long' | undefined;
};

export interface SafeFormatDateSignature {
  Args: {
    Positional: Positional;
    Named: Named;
  };
  Return: string | undefined;
}

/**
 * Returns a formatted date if a parsable value exists, otherwise return null
 * without raising an app-blocking error.
 *
 * {{safeFormatDate @createdAt}}
 *
 */
export default class SafeFormatDate extends Helper<SafeFormatDateSignature> {
  @service declare intl: IntlService;

  compute([valueToFormat]: Positional, options: Named = {}) {
    if (!valueToFormat) {
      return;
    }

    try {
      return this.intl.formatDate(valueToFormat, options);
    } catch (e) {
      console.warn(e);
    }
    return;
  }
}
