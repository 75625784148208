import Model, { attr, belongsTo } from '@ember-data/model';
import type CompanyModel from './company.ts';
import type PslfGroupModel from './pslf-group.ts';

export type CompanySettingEnum = {
  [key: string]: {
    name: string;
    partnerLabel: string;
  }[];
};

export type CompanySettingInvoicing = {
  invoiceDay: number;
  netDueDays: number;
  requiresApproval: boolean;
  splitByCostCenter: boolean;
  serviceFeePoNumber: string;
  wellnessServiceFee: number;
  contributionPoNumber: string;
  autoApproveAfterNDays: number;
  contributionServiceFee: number;
  adoptionFloorParticipantCount: number;
  adoptionFloorParticipantAmount: number;
  billAllParticipantsForWellness: boolean;
};

export type CompanySettingQuicksight = {
  dashboards: {
    id: string;
    active: boolean;
    custom: boolean;
    titleKey: string;
    companyId: string;
    reportType: string[];
  }[];
};

type Address = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  postalCode: string;
};

export type PslfModuleEmployer = {
  services: string[];
  isPolitical: boolean;
  isTaxExempt: boolean;
  isGovernment: boolean;
  isNotForProfit: boolean;
  legalName: string;
  fein: string;
  website: string;
  address: Address;
};

export type PslfModule = {
  groups: PslfGroupModel[];
  enabled: boolean;
  employer: PslfModuleEmployer;
  enabledOn: string;
  isNonPartnerEmployer: boolean;
  isEnabledPriorEmployer: boolean;
  isNonPartnerApprover: boolean;
};

export type CompanySettingModules = {
  pslf?: PslfModule;
  syf?: { isEnabled: boolean };
  support?: {
    faqs: { isEnabled: boolean };
    zendesk: { isEnabled: boolean };
    tioEmail: { isEnabled: boolean };
    tioPhone: { isEnabled: boolean };
    partnerEmail?: string;
    partnerPhone?: string;
  };
};

export type CompanySettingTuitionAssistance = {
  taOnlyPlanId: string;
  isTaMentoringEnabled: boolean;
  isTaOnly: boolean;
  isTaEnabled?: boolean;
  eligibilityWaitingPeriodDays?: number;
  isDependentProgramsEnabled?: boolean;
};

// These are technically all optional, but we should make a model
// that would give reasonable defaults.
export type CompanySettingRegister = {
  'is-email-register-allowed': boolean;
  'show-countdown': boolean;
  'is-payroll-id-and-last-name-allowed': boolean;
  'require-custom-terms': boolean;
  'custom-terms-text': string;
  'is-self-register-allowed': boolean;
  'self-register-domain': string;
};

export type EmployeeCustomAttribute = {
  field: string;
  note: string;
  required: boolean;
  custom: boolean;
  postProcess?: string;
};

export type LandingPage = {
  isEnabled: boolean;
  title: string;
  html: string;
  buttonText: string;
  externalLoginUrl: string;
};
export type CompanySettingFiscalYear = {
  'start-month': number;
};

export default class CompanySettingModel extends Model {
  @attr declare allowFamilyPlan: boolean;
  @attr declare eligibilityDefault: Record<string, unknown>;
  @attr declare emailOverrides: Record<string, unknown>;
  @attr declare employeeCustomAttributes: EmployeeCustomAttribute[];
  @attr declare employeePortal529Tab: string;
  @attr declare employeePortalTab: string;
  @attr declare enums: CompanySettingEnum;
  @attr declare extraAuthVerification: boolean;
  @attr declare gradvisorDomain: string;
  @attr declare idProvider: string;
  @attr declare imputedIncome: Record<string, unknown>;
  @attr declare invoicing: CompanySettingInvoicing;
  @attr declare isDefault: boolean;
  @attr declare isGlobal: boolean;
  @attr declare landingPage: LandingPage;
  @attr declare loanEligibility: Record<string, unknown>;
  @attr declare modules: CompanySettingModules;
  @attr declare newDesign: boolean;
  @attr declare partnerType: string;
  @attr declare payrollCustomText: string;
  @attr declare provider: string;
  @attr declare quickSight: CompanySettingQuicksight;
  @attr declare register: CompanySettingRegister;
  @attr declare registerPageCopy: string;
  @attr declare security: Record<string, unknown>;
  @attr declare sftpUserName: string;
  @attr declare ssoAuthUrl: string;
  @attr declare subDomain: string;
  @attr declare tuitionAssistance: CompanySettingTuitionAssistance;
  @attr declare tuitionmanagerAccount: string;
  @attr declare fiscalYear: CompanySettingFiscalYear;

  @belongsTo('company', { async: true, inverse: 'companySetting' })
  declare company: CompanyModel;

  get employmentTypes() {
    return this.enums['EmploymentType'] || [];
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'company-setting': CompanySettingModel;
  }
}
