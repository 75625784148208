import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type EmployeeModel from './employee.ts';
import type TASProgramInstanceModel from './tas-program-instance.ts';

export default class DependentModel extends Model {
  @attr declare birthDate: string;
  @attr declare email: string;
  @attr declare firstName: string;
  @attr declare lastName: string;
  @attr declare relationship: string;
  @attr declare createdAt: string;
  @attr declare updatedAt: string;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('employee', { async: false, inverse: null })
  declare employee: EmployeeModel;
  @hasMany('tas-program-instance', { async: false, inverse: 'dependent' })
  declare tasProgramInstances: TASProgramInstanceModel[];

  /**************************
   **  Computed Properties **
   **************************/

  get fullName() {
    return [this.firstName, this.lastName].join(' ');
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    dependent: DependentModel;
  }
}
