import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type WorkspaceModel from './workspace.ts';
import { getInitials } from '../utils/data/get-initials.ts';

type CustomAttribute = {
  'agency-name'?: string;
  'agent-name'?: string;
  'agent-address-line1'?: string;
  'agent-address-line2'?: string;
  'agent-license'?: string;
};

export default class AgentModel extends Model {
  @attr declare firstName: string;
  @attr declare lastName: string;
  @attr declare email: string;
  @attr declare customAttributes: CustomAttribute;

  /*************************
   **  Relationships      **
   *************************/

  @belongsTo('workspace', { async: false, inverse: 'agent' })
  declare workspace: WorkspaceModel;
  @hasMany('workspace', { async: false, inverse: null })
  declare workspaces: WorkspaceModel[];

  /**************************
   **  Computed Properties **
   **************************/

  get fullName() {
    return [this.firstName, this.lastName].join(' ');
  }

  get initials() {
    return getInitials(this);
  }
}

declare module '@ember-data/types/registries/model' {
  export default interface ModelRegistry {
    agent: AgentModel;
  }
}
